// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `prodEnabled` ensures that the angular core function `enableProdMode()`
// in the ts file of each elements component is only called once per hosting application.
// If it is called multiple times (when multiple components from this library
// are used within the same feature) angular core throws the error
// 'Cannot enable prod mode after platform setup.' and only the first elements component loads.
// The reason for the error is that in this library we only use one package.json
// which means the same instance of angular core is shared between the components of the library.

export const environment = {
  apiUrl: 'https://api.stage.ngin-staging.com/',
  awsUrl: 'https://se-financial-admin.ui.stage.ngin-staging.com/',
  production: false,
  staging: true,
  prodEnabled: false,
  launchDarklyClientId: '5f4fc4507c0c78095ceeb4ed',
  domainEnvironments: [
    {
      domain: 'se-financial-admin.ui.stage.ngin-staging.com',
      default: true,
      urls: { api: 'https://api.stage.ngin-staging.com/' }
    },
    {
      domain: 'se-financial-admin.ui.sestage.us',
      default: false,
      urls: { api: 'https://se-api.sestage.us/' },
    }
  ],
  // The following is for integration with se-fe-auth-support
  domainSpecificUrls: [
    {
      domain: 'ngin-staging.com',
      default: true,
      api: 'https://api.stage.ngin-staging.com/',
      user: 'https://user.stage.ngin-staging.com/'
    },
    {
      domain: 'sestage.us',
      default: false,
      api: 'https://api.stage.ngin-staging.com/',
      user: 'https://user.stage.ngin-staging.com/'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
